import $ from "jquery";
import StatCookie from "./StatCookie";
import User from "./User";
import StatContent from "./StatContent";

class Stat
{
    static get URL() { return 'https://analytics.wearebab.com/stats/save'; };
    static get TYPE_UNIQUE() { return 1; };
    static get TYPE_VISIT() { return 2; };
    static get TYPE_VIEW() { return 3; };
    static get THRESHOLD_ADD_VISIT() { return 30 * 60 * 1000; };
    static get LOGIN_EVENT() { return 'ba_stat_login'; };
    static get LOGOUT_EVENT() { return 'ba_stat_logout'; };
    static get VIEW_EVENT() { return 'ba_stat_view'; };

    constructor()
    {
        this.params = new URLSearchParams(window.location.search);
        this.account = this.resolveAccount();
        this.platform = this.resolvePlatform();

        if (!this.account) {
            return;
        }

        this.init();
        this.commit();
    }

    init()
    {
        this.cookie = new StatCookie();
        this.locked = false;

        document.addEventListener(Stat.LOGIN_EVENT, this.loginStat);
        $(document).on(Stat.LOGIN_EVENT, this.loginStat);
        document.addEventListener(Stat.LOGOUT_EVENT, this.logoutStat);
        $(document).on(Stat.LOGOUT_EVENT, this.logoutStat);
        document.addEventListener(Stat.VIEW_EVENT, this.commit);
        $(document).on(Stat.VIEW_EVENT, this.commit);
    }

    resolveAccount()
    {
        if (
            typeof(window._baConfig) !== "undefined"
            && typeof(window._baConfig.account) === "string"
            && window._baConfig.account !== ''
        ) {
            return window._baConfig.account;
        }

        if (this.params.has('account')) {
            return this.params.get('account');
        }

        return null;
    }

    resolvePlatform()
    {
        if (
            typeof(window._baConfig) !== "undefined"
            && typeof(window._baConfig.platform) === "string"
            && window._baConfig.platform !== ''
        ) {
            return window._baConfig.platform;
        }

        if (this.params.has('platform')) {
            return this.params.get('platform');
        }

        return 'web';
    }

    commit()
    {
        let $this = this;

        if (!$this.lock()) {
            return;
        }

        $.ajax({
            method: 'post',
            url: Stat.URL,
            data: $this.generateStatData(),
            complete: function(response)
            {
                if (response.status === 200) {
                    $this.cookie.persist();
                }
                $this.unlock();
            }
        });
    }

    generateStatData()
    {
        let data = {
            token: this.account,
            platform: this.platform,
            type: this.resolveStatType(),
            referrer: document.referrer,
            logged: this.resolveLogged(),
            userId: this.cookie.getUserId(),
            userAgent: navigator.userAgent,
            uri: location.pathname,
            content: StatContent.generate(),
            locale: $('html').attr('lang'),
            screen: ""
        };

        if (typeof(screen.availWidth) !== "undefined" && typeof(screen.availHeight) !== "undefined") {
            data.screen = screen.availWidth + "x" + screen.availHeight;
        }

        return data;
    }

    resolveStatType()
    {
        let currentDate = new Date();
        let value = this.cookie.getDate();

        if (value === null) {
            return Stat.TYPE_UNIQUE;
        }

        if (value.getMonth() !== currentDate.getMonth() || value.getFullYear() !== currentDate.getFullYear()) {
            return Stat.TYPE_UNIQUE;
        }

        if ((currentDate.getTime() - value.getTime()) > Stat.THRESHOLD_ADD_VISIT) {
            return Stat.TYPE_VISIT;
        }

        return Stat.TYPE_VIEW;
    }

    resolveLogged()
    {
        let user = new User();

        return user.isLogged();
    }

    lock()
    {
        if (this.locked) {
            return false;
        }

        this.locked = true;
        return true;
    }

    unlock()
    {
        this.locked = false;
    }

    loginStat()
    {
        let user = new User();
        user.login();

        this.commit();
    }

    logoutStat()
    {
        let user = new User();
        user.logout();
        this.commit();
    }
}

export default Stat;
