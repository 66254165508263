import $ from "jquery";

class StatContent
{
    static generate()
    {
        let contentType = StatContent.resolveType();

        if (contentType === "") {
            return {};
        }

        let json = StatContent.resolveJson();

        return {
            type: contentType,
            title: StatContent.resolveTitle(json),
            category: StatContent.resolveCategory(json),
            tag: StatContent.resolveTag(json),
            author: StatContent.resolveAuthor(json),
            published: StatContent.resolvePublished(json),
            modified: StatContent.resolveModified(json),
        }
    }

    static resolveJson()
    {
        let json = $('[type="application/ld+json"]:last').text();

        if (json !== '') {
            return $.parseJSON(json);
        }

        return {};
    }

    static resolveTitle(json)
    {
        if (typeof(json.headline) !== "undefined") {
            return json.headline;
        }

        return StatContent.resolveTagValue('og:title');
    }

    static resolveImage(json)
    {
        if (typeof(json.image) !== "undefined") {
            return json.image.url;
        }

        return StatContent.resolveTagValue('og:image');
    }

    static resolveType()
    {
        return StatContent.resolveTagValue('stats:type');
    }

    static resolveCategory(json)
    {
        if (typeof(json.articleSection) !== "undefined") {
            return json.articleSection;
        }

        return StatContent.resolveTagValue('stats:category');
    }

    static resolveTag(json)
    {
        if (typeof(json.keywords) !== "undefined") {
            return json.keywords;
        }
        return StatContent.resolveTagValue('stats:tag');
    }

    static resolveAuthor(json)
    {
        if (typeof(json.author) !== "undefined") {
            return json.author.name;
        }

        return StatContent.resolveTagValue('stats:author');
    }

    static resolvePublished(json)
    {
        if (typeof(json.datePublished) !== "undefined") {
            return json.datePublished;
        }

        return StatContent.resolveTagValue('stats:published');
    }

    static resolveModified(json)
    {
        if (typeof(json.dateModified) !== "undefined") {
            return json.dateModified;
        }

        return StatContent.resolveTagValue('stats:modified');
    }

    static resolveTagValue(tag)
    {
        let item = $('head meta[property="'+tag+'"]');

        if (item.length <= 0) {
            return "";
        }

        return item.attr('content');
    }
}

export default StatContent;
