import UserCookie from "./UserCookie";

class User
{
    constructor()
    {
        this.cookie = new UserCookie();
    }

    isLogged()
    {
        let value = parseInt(this.cookie.getData(), 10);

        if (isNaN(value) || value <= 0) {
            return false;
        }

        return true;
    }

    login()
    {
        this.cookie.setData(1);
    }

    logout()
    {
        this.cookie.remove();
    }
}

export default User;
