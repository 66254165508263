class Cookie
{
    constructor(name)
    {
        this.name = name;

        if (this.name === "") {
            return;
        }

        this.data = (this.retrieve() || "");
        this.options = {
            domain: location.hostname.replace('www.', ''),
            path: "/",
            secure: (location.protocol.match('^https') !== null)
        };
    }

    retrieve()
    {
        let start = document.cookie.indexOf(this.name + "=");

        if (start === -1 || this.name === document.cookie.substr(start, this.name.length)) {
            return "";
        }

        let len = start + this.name.length + 1;
        let end = document.cookie.indexOf(';', len);

        if (end === -1) {
            end = document.cookie.length;
        }

        return unescape(document.cookie.substring(len, end));
    }

    getData()
    {
        return this.data;
    }

    setData(value, months)
    {
        this.data = value;
        this.store(months);
    }

    store(months)
    {
        document.cookie = this.name + '=' + encodeURIComponent(this.data)
            + this.getOptions()
            + this.generateExpires(months);
    }

    remove()
    {
        document.cookie = this.name + '=' + this.getOptions() + ';expires=' + (new Date(0)).toUTCString();
    }

    generateExpires(months)
    {
        months = parseInt(months, 10);

        if (isNaN(months) || months <= 0) {
            return "";
        }

        let today = new Date();
        let expires = new Date(today.getFullYear(), today.getMonth()+months, 1, 0, 0);
        return ';expires=' + expires.toUTCString();
    }

    getOptions()
    {
        return (this.options.path ? ';path=' + this.options.path : '')
            + (this.options.domain ? ';domain=' + this.options.domain : '')
            + (this.options.secure ? ';secure' : '');
    }
}

export default Cookie;
