import Cookie from "./Cookie";
import {validate as uuidCheck, v4 as uuid4} from 'uuid';

class StatCookie extends Cookie
{
    static get COOKIE_NAME() { return 'ba_stat'; };
    static get MTL() { return 6; };

    constructor()
    {
        super(StatCookie.COOKIE_NAME);
        this.generateUserId();
    }

    resolveData()
    {
        if (this.data === "") {
            this.data = "{}";
        }

        return JSON.parse(this.data);
    }

    getDate()
    {
        let info = this.resolveData();

        if (typeof(info.time) === "undefined") {
            return null;
        }

        info.time = parseInt(info.time, 10);

        if (isNaN(info.time) || info.time <= 0) {
            return null;
        }

        return new Date(info.time);
    }

    setDate(date)
    {
        if (!date || typeof(date) !== "object") {
            return;
        }

        let info = this.resolveData();
        info.time = date.getTime();
        this.data = JSON.stringify(info);
    }

    getUserId()
    {
        let info = this.resolveData();

        if (typeof(info.userId) === "undefined") {
            return "";
        }

        return info.userId;
    }

    setUserId(userId)
    {
        if (!uuidCheck(userId)) {
            return;
        }

        let info = this.resolveData();
        info.userId = userId;
        this.data = JSON.stringify(info);
    }

    generateUserId()
    {
        if (this.getUserId() !== "") {
            return;
        }

        this.setUserId(uuid4());
    }

    persist()
    {
        this.setDate(new Date());
        this.store(StatCookie.MTL);
    }
}

export default StatCookie;
