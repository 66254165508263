import Cookie from "./Cookie";

class UserCookie extends Cookie
{
    static get COOKIE_NAME() { return 'ba_logged'; };

    constructor()
    {
        super(UserCookie.COOKIE_NAME);
    }
}

export default UserCookie;
